@import '../theme-bootstrap';

.product__estimated-delivery {
  padding: 1.5em 2em 2em;
  &-header {
    @include h9;
    margin-top: 0;
    margin-bottom: 0.5em;
    min-height: 0.5em;
    color: $color_green;
  }
  &-preface {
    margin-bottom: 1em;
  }
  &-disclaimer {
    display: none;
    margin-top: 0.5em;
  }
  &-data {
    margin: 1em 0;
    &-table,
    &-error {
      display: none; // default state, hidden until shown by the JS
    }
    &-table {
      th,
      td {
        padding: 0.5em;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      th {
        @include h12;
      }
    }
  }
}
